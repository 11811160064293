import Vue from 'vue'
import { Button, Dialog, Notification, Message, MessageBox, Container, Main, Footer, Header, Result , Carousel , CarouselItem , Row , Col , Form , 
    Input , FormItem , Checkbox , Link , InfiniteScroll , Image , Empty , Breadcrumb , BreadcrumbItem} from 'element-ui'

Vue.use(Button)
Vue.use(Container)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Header)
Vue.use(Result)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Input)
Vue.use(FormItem)
Vue.use(Checkbox)
Vue.use(Link)
Vue.use(InfiniteScroll)
Vue.use(Image)
Vue.use(Empty)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)

Vue.prototype.$notification = Notification;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$msgbox = MessageBox ;
Vue.prototype.$message = Message ;

