import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { ACCESS_TOKEN } from '@/consts/config.js'
import { lStorage } from '@/utils'

// NProgress Configuration
NProgress.configure({ showSpinner: false })


router.beforeEach(async (to, from, next) => {
    NProgress.start() // start progress bar
    
    let token = lStorage.get(ACCESS_TOKEN);
    store.commit('SET_LOGIN', !!token)
    /* has token */
    if (token) {
        next()
    } else {
        if (to.meta.needLogin) {
            next({ path: '/home' })
            Vue.prototype.$bus.$emit('needLogin', {redirectTo: to.fullPath})
            NProgress.done()
        } else {
             // 在免登录白名单，直接进入
             next()
             NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
