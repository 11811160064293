const app = {
    state: {
    },
    mutations: {

    },
    actions: {
    }
}

export default app