import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { lStorage, sleep } from '@/utils'
import { ACCESS_TOKEN, AUTHORI_ZATION } from "@/consts/config.js"
import signMd5Utils from '@/utils/signMd5Utils.js'


// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_AXIOS_URL_PREFIX,
    timeout: 15000 // 请求超时时间
})

const err = async (error) => {
    if (error.response) {
        let data = error.response.data
        const token = lStorage.get(ACCESS_TOKEN)
        console.log("------异常响应------", error.response.status)
        switch (error.response.status) {
            case 403:
                Vue.prototype.$notification.error({ title: '系统提示', message: '拒绝访问', duration: 4000 })
                break
            case 500:
                console.log("------error.response------", error.response)

                if (token && data?.message?.includes("Token失效")) {

                    Vue.prototype.$msgbox({
                        title: '登录已过期',
                        message: '很抱歉，登录已过期，请重新登录',
                        confirmButtonText: '重新登录',
                        mask: false,
                        callback: async (action) => {
                            if(action === 'confirm') {
                                await store.dispatch('Logout')
                                window.location.reload()
                            }
                        }
                    })
                }
                break
            case 404:
                Vue.prototype.$notification.error({ title: '系统提示', message: '很抱歉，资源未找到!', duration: 4000 })
                break
            case 504:
                Vue.prototype.$notification.error({ title: '系统提示', message: '网络超时' })
                break
            case 401:
                Vue.prototype.$notification.error({ title: '系统提示', message: '很抱歉，登录已过期，请重新登录', duration: 4000 })
                await store.dispatch('Logout')
                await sleep(1500)
                break
            default:
                Vue.prototype.$notification.error({
                    title: '系统提示',
                    message: data?.message,
                    duration: 4000
                })
                break
        }
    } else if (error.message) {
        if (error.message.includes('timeout')) {
            Vue.prototype.$notification.error({ title: '系统提示', message: '网络超时' })
        } else {
            Vue.prototype.$notification.error({ title: '系统提示', message: error.message })
        }
    }
   throw (error?.response?.data || {})
};

function appendParamsToUrl(url, params) {
    // 将对象的键值对转换为查询字符串
    const queryString = Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  
    // 检查 URL 中是否已经有查询字符串
    if (url.includes('?')) {
      return `${url}&${queryString}`;
    } else {
      return `${url}?${queryString}`;
    }
  }

// request interceptor
service.interceptors.request.use(config => {
    const token = lStorage.get(ACCESS_TOKEN)

    if (token) {
        config.headers[AUTHORI_ZATION] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    let url = config.url;
    if(config.params) {
        url = appendParamsToUrl(config.url, config.params)
    }
    // 签名
    let sign = signMd5Utils.getSign(url, config.data || {});
    config.headers['X-Sign'] = sign
    config.headers['X-TIMESTAMP'] = signMd5Utils.getDateTimeToString()

    if(config.url.indexOf('noauth/') === -1 && !token) {
        //登录过期自动登录
        Vue.prototype.$msgbox({
            title: '登录已过期',
            message: '很抱歉，登录已过期，请重新登录',
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            mask: false,
            callback: async (action) => {
                if(action === 'confirm') {
                    await store.dispatch('Logout')
                    Vue.prototype.$bus.$emit('needLogin')
                }
            }
        })
        
        return Promise.reject({ message: '登录已过期' })
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(async (response) => {
    let data = response?.data || {message: "请求失败"}
    if(data?.code === 200) {
        return data;
    }else if(data?.code === 401) {
        Vue.prototype.$msgbox({
            title: '登录已过期',
            message: '很抱歉，登录已过期，请重新登录',
            confirmButtonText: '重新登录',
            mask: false,
            callback: async (action) => {
                if(action === 'confirm') {
                    await store.dispatch('Logout')
                    window.location.reload()
                }
            }
        })
    }else {
        throw data;
    }
}, err)

export {
    service as axios
}