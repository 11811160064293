import { lStorage } from '@/utils';
import router from '@/router'
import { ACCESS_TOKEN } from '@/consts/config'
import { LoginApi, LoginOutApi, GetUserInfoApi } from '@/api/index.js';

const user = {
    state: {
        access_token: lStorage.get(ACCESS_TOKEN) || '',
        userInfo: lStorage.get('userInfo') || {},
        isLogin: lStorage.get('isLogin') || false,
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.access_token = token
            lStorage.set(ACCESS_TOKEN, token)

            state.isLogin = !!token
            lStorage.set('isLogin', !!token)
        },
        SET_LOGIN: (state, value) => {
            state.isLogin = value;
            lStorage.set('isLogin', value)
        },
        // 用户信息，随时往里加属性
        SET_USERINFO: (state, data) => {
            state.userInfo = data;
            lStorage.set('userInfo', data)
        }
    },

    actions: {
        // 登录
        async Login({ commit , dispatch}, param) {
            try {
                let { result: { token, accompanyUserInfo } } = await LoginApi(param)
                commit('SET_TOKEN', token)
                commit('SET_USERINFO', accompanyUserInfo)
                dispatch('getUserInfo')
            } catch (error) {
                commit('SET_TOKEN', '')
                commit('SET_USERINFO', {})
                throw error
            }
        },
        // 获取用户信息
        async getUserInfo({ commit }) {
            try {
                let { result = {} } = await GetUserInfoApi()
                // console.log(result);
                commit('SET_USERINFO', result)
            } catch (error) {
                console.error(error)
                commit('SET_USERINFO', {})
            }
        },
        // 登出
        async Logout({ commit }) {
            try {
                await LoginOutApi()
                commit('SET_TOKEN', '')
                commit('SET_USERINFO', '')
                router.push('/home')
            } catch (error) {
                console.error(error)
                throw { message: error.message || '退登失败, 请重试' }
            }
        },
    }
}

export default user
