/**
 * @desc  函数节流
 * @param {Function} fn
 * @param {Number} wait
 * @returns {Function}
 */
export function throttle(fn, wait) {

    var context, args
    var previous = 0

    return async function () {
        var now = +new Date()
        context = this
        args = arguments
        if (now - previous > wait) {
            let data = await fn.apply(context, args)
            previous = now
            return data;
        }
    }
}

/**
 * @desc  函数防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(method, wait, immediate) {
    let timeout
    return function (...args) {
        let context = this
        if (timeout) {
            clearTimeout(timeout)
        }
        // 立即执行需要两个条件，一是immediate为true，二是timeout未被赋值或被置为null
        if (immediate) {
            /**
             * 如果定时器不存在，则立即执行，并设置一个定时器，wait毫秒后将定时器置为null
             * 这样确保立即执行后wait毫秒内不会被再次触发
             */
            let callNow = !timeout
            timeout = setTimeout(() => {
                timeout = null
            }, wait)
            if (callNow) {
                method.apply(context, args)
            }
        } else {
            // 如果immediate为false，则函数wait毫秒后执行
            timeout = setTimeout(() => {
                /**
                 * args是一个类数组对象，所以使用fn.apply
                 * 也可写作method.call(context, ...args)
                 */
                method.apply(context, args)
            }, wait)
        }
    }
}

/**
 * @desc  睡一会儿，让子弹暂停一下
 * @param {number} time 毫秒数
 * @returns
 */
export function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
}

/**
 * @param {HTMLElement} el
 * @param {Function} cb
 * @return {ResizeObserver}
 */
export function useResize(el, cb) {
    const observer = new ResizeObserver((entries) => {
        cb(entries[0].contentRect)
    })
    observer.observe(el)
    return observer
}

/**
 * @param {file} img
 * @param {Function} callback
 */
export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
/**
 * 时间相差天数
 * @param {*} date1 开始时间
 * @param {*} date2 结束时间
 * @returns 
 */
export function getDistanceDays(date1, date2) {
    const date1_timeStamp = new Date(date1)
    const date2_timeStamp = new Date(date2)
    const nowTime=new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()
    // let date1Time=new Date(date1).getFullYear()+"-"+(new Date(date1).getMonth()+1)+"-"+new Date(date1).getDate()
    let date2Time=new Date(date2).getFullYear()+"-"+(new Date(date2).getMonth()+1)+"-"+new Date(date2).getDate()
    if(date2Time==nowTime){
        let max = '',
        min = ''
        if (date1_timeStamp > date2_timeStamp) {
            max = date1_timeStamp
            min = date2_timeStamp
        } else {
            max = date2_timeStamp
            min = date1_timeStamp   
        }
        let num=(max - min) / (24 * 60 * 60 * 1000)
        if(num<1){
            return 'today'
        }else if(num==1){
            return 'yesterday'
        }else if(num==7){
            return 'sevenDays'
        }else if(num==30){
            return 'thirtyDays'
        }
    }else {
        return false
    }
}

/**
 * 倒计时
 * @param {Number} seconds 倒计时长 默认60秒
 * @param {Function} callback 每过一秒回调一次
 */
export async function countDown(seconds = 60, callback) {
    
    while(seconds >= 0){
        callback(seconds)
        await sleep(1000)
        seconds--
    }

    return Promise.resolve()

}

/**加载script标签
 * @param {String} src script的地址
 * @param {Function} callback 加载完成的回调
 */
export function scriptLoader(src, callback) {
    // 创建 script 标签
    var script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';

    // 定义脚本加载成功后的回调函数
    script.onload = function() {
        console.log('Script loaded successfully.');
        // 调用脚本加载成功后的方法
        callback();
    };

    // 将 script 标签添加到文档中
    document.head.appendChild(script);
}