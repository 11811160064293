import { isEmail, isMobile, isCode, isPassword, isID, isLicense } from "./validate";

// 校验纯数字密码
let validatePassword = (rule, value, callback) => {
    if (isPassword(value)) {
        callback();
    }else {
        callback(new Error('支持8-20位，必须由英文字母、数字或符号组成'));
    }
};
// 校验手机号
let validatePhone = (rule, value, callback) => {
    if (isMobile(value)) {
         callback();
    }else {
        callback(new Error('请输入正确手机号！'));
    }
};

// 校验验证码
let validateCode = (rule, value, callback) => {
    if (isCode(value)) {
        callback();
    }else {
        callback(new Error('请输入4位验证码！'));
    }
};

// 校验邮箱
let validateEmail = (rule, value, callback) => {
    if (isEmail(value)) {
        callback();
    }else {
        callback(new Error('请输入正确的邮箱！'));
    }
};

// 校验账号 可以是 手机号/邮箱
let validateAccount = (rule, value, callback) => {
    if (isEmail(value) || isMobile(value)) {
        callback();
    }else {
        callback(new Error('请输入正确的邮箱或手机号！'));
    }
};

// 校验身份证号
let validateID = (rule, value, callback) => {
    if (isID(value)) {
        callback();
    }else {
        callback(new Error('请输入正确的身份证号！'));
    }
};

// 校验营业执照
let validateLicense = (rule, value, callback) => {
    if (isLicense(value)) {
        callback();
    }else {
        callback(new Error('请输入正确的营业执照号！'));
    }
};



export {
    validatePassword,
    validatePhone,
    validateCode,
    validateEmail,
    validateAccount,
    validateID,
    validateLicense,
}

