import { httpAction } from '@/utils/request_manage.js';

//协议列表
export function AgreementApi(params) {
    return httpAction('get', '/noauth/agreement/getAgreement', params)
}

//登录
export function LoginApi(params) {
    return httpAction('post', '/noauth/accompanyLogin', params)
}

//退出登录 
export function LoginOutApi(params) {
    return httpAction('post', '/noauth/pc/officialWebsiteLogout', params)
}

//发送验证码
export function sendPhoneCodeApi(params) {
    return httpAction('post', '/noauth/accompanyLogin', params)
}

//获取用户信息
export function GetUserInfoApi(params) {
    return httpAction('get', '/pc/officialWebsite/getUserInfo', params)
}




//首页广告轮播图 
export function bannerListApi(params) {
    return httpAction('get', '/noauth/public/bannerList', params)
}

// 关于我们
export function AboutUsApi(params) {
    return httpAction('get', '/noauth/pc/officialWebsiteAbout/getAboutById', params)
}

//课程列表
export function VideoCategoryListApi(params) {
    return httpAction('get', '/pc/officialWebsiteVideoCategory/selectVideoCategoryList', params)
}

//课程详情(视频分类) 
export function getVideoInfoApi(params) {
    return httpAction('get', '/pc/officialWebsiteVideoCategory/getVideoCategoryById', params)
}

//课程目录分页列表(视频)
export function selectVideoListApi(params) {
    return httpAction('get', '/pc/officialWebsiteVideo/selectVideoList', params)
}

//获取观看视频地址
export function getVideoPlayUrlApi(params) {
    return httpAction('get', '/pc/officialWebsiteVideo/getVideoInfo', params)
}

//新增或修改观看课程的历史记录 
export function saveVideoWatchApi(params) {
    return httpAction('post', '/pc/officialWebsiteVideo/removeAndSaveVideoWatchLog', params)
}
