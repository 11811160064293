/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
    // return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
    return /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/.test(s);
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
    return /^1[3-9]\d{9}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
    return /^http[s]?:\/\/.*/.test(s)
}

/**
* 匹配8-20位的密码, 必须包含（特殊字符，数字，字母）中的任意两项
* 特殊字符集  !@#$%^&*_-+=/?;:,.~`
* 数字 0-9
* 字母 a-z A-Z
* @param {*} password
*/
export function isPassword(password) {
    // 定义字符类别
    const specialChars = /[!@#$%^&*_\-+=/?;:,.~`]/;
    const digits = /[0-9]/;
    const letters = /[a-zA-Z]/;

    // 检查长度
    const lengthValid = password.length >= 8 && password.length <= 20;

    // 检查包含的字符类别
    const hasSpecialChar = specialChars.test(password);
    const hasDigit = digits.test(password);
    const hasLetter = letters.test(password);

    // 检查至少包含任意两项
    const categoriesCount = [hasSpecialChar, hasDigit, hasLetter].filter(Boolean).length;

    // 确保只包含特定字符
    const validChars = /^[a-zA-Z0-9!@#$%^&*_\-+=/?;:,.~`]+$/.test(password);

    // 返回验证结果
    return lengthValid && categoriesCount >= 2 && validChars;
}


/**
* 是否是6位验证码(数字/字母)
* @param {*} s
*/
export function isCode(s) {
    return /^[\da-zA-Z]{4}$/.test(s)
}

/**
* 是否是身份证号
* @param {*} s
*/
export function isID(s) {
    return /(^\d{17}(\d|X|x)$)/.test(s)
}

/**
* 是否是营业执照
* @param {*} s
*/
export function isLicense(s) {
    return /^[0-9A-Z]{15}$|^[0-9A-Z]{18}$/.test(s)
}
