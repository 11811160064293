import { axios } from '@/utils/request'

//post method= {get | post | put | delete}
export function httpAction(method, url, parameter) {
    let param = method == 'get' ? {params:parameter} : {data:parameter}
    return axios({
        url: url,
        method: method,
        ...param,
    })
}

/**
 * 文件上传 用于富文本上传图片
 * @param url
 * @param parameter
 * @returns {*}
 */
export function uploadAction(url, parameter, onUploadProgress){
    return axios({
      url: url,
      data: parameter,
      method:'post' ,
      headers: {
        'Content-Type': 'multipart/form-data',  // 文件上传
      },
      onUploadProgress,
    })
  }
  
//下载
export function downloadAction(url, parameter) {

    return axios({
        url: url,
        method: 'get',
        params: parameter,
        responseType: 'blob',
    })
}